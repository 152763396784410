import React from 'react'
import Layout from '../../components/Layout'
import { graphql, Link } from 'gatsby'
import MvTextPage from '../../components/common/MvTextPage'
import MvImage from '../../components/common/MvImage'
import MvTextPageSection from '../../components/common/MvTextPageSection'
import MvRegister from '../../components/MvRegister'

export default ({ data }) => {
  const sections = ['Trachtenkapelle', 'Jugendkapelle', 'Frauentreff']
  return (
    <Layout>
      <MvTextPage title="Verein" sections={sections}>
        <MvTextPageSection title="Trachtenkapelle">
          <p>
            Eine beachtliche Anzahl von 80 Musikerinnen und Musikern zählt die
            Trachtenkapelle im Jahr 2020 - Tendenz seit 140 Jahren fast immer
            steigend! Zur musikvereinsüblichen Besetzung mit Flöten,
            Klarinetten, Saxophonen, Trompeten, Flügelhörnern, Posaunen,
            Tenorhörnern, Baritonen, Tuben und Schlagwerk kommen in Winterbach
            noch E-Gitarre, E-Bass, Oboen, Fagott und Bassklarinette hinzu.
            Allein schon diese außergewöhnliche Zusammensetzung legt nahe, dass
            im Musikverein Trachtenkapelle Winterbach nicht nur die
            traditionelle Blasmusik gepflegt wird. Auch symphonische und moderne
            Blasmusik kommen nicht zu kurz.
          </p>

          <MvImage
            fluid={data.trachtenkapelle.childImageSharp.fluid}
            className="my-8"
            caption="Trachtenkapelle im Jahr 2023"
            photographer="Daniel Stimac"
          />

          <p>
            Und so wird bei der jährlichen Winterunterhaltung regelmäßig unter
            Beweis gestellt, dass es den vielen talentierten Musikerinnen und
            Musikern unter Leitung ihres Dirigenten Ralf Kaiser durchaus
            gelingen kann, innerhalb eines einzigen Konzertabends wie die
            "Original Egerländer Musikanten", wie "Santana", wie "Deep Purple",
            wie die "Beatles", wie "ABBA", wie eine Bigband oder sogar (fast)
            wie die "Berliner Philharmoniker" zu klingen. Schon allein ein solch
            vielfältiges Konzertprogramm ist außergewöhnlich. Dass darunter das
            Niveau keinesfalls leidet, bescheinigt die strenge Jury des
            "Baden-Württembergischen Blasmusikverbandes Rems-Murr" der
            Trachtenkapelle in regelmäßigem Abstand: Die Wertungsspiele besteht
            der Musikverein mit besten Noten in der Oberstufe.
          </p>

          <p>
            Aber nicht nur musikalisch lassen sich Tradition und Fortschritt
            bestens vereinigen: Besonders stolz können die Mitglieder des
            Vereins auch auf das gute Miteinander von Jung und Alt sein. Und
            dabei stimmen Teenager völlig mit "Mittelalten" und Rentnern
            überein: Stimmung, Kameradschaft und Zusammenhalt könnten nicht
            besser sein!
          </p>

          <h3 className="mt-12">Dirigent</h3>
          <div className="md:flex md:items-start md:flex-row">
            <div>
              <p>
                Seit Juli 2024 ist <strong>Ralf Kaiser</strong> neuer Dirigent
                der Trachtenkapelle. Wir freuen uns mit ihm auf eine gute und
                erfolgreiche Zusammenarbeit und auf viele schöne gemeinsame
                musikalische Erlebnisse.
              </p>
              <p>Weitere Informationen folgen. </p>
            </div>
            <MvImage
              fluid={data.dirigent.childImageSharp.fluid}
              className="my-8 md:my-0 md:ml-8 md:w-1/2 md:flex-no-shrink"
              photographer="Benjamin Rupp"
              disableToggle={true}
            />
          </div>

          <MvRegister
            name="Flöten"
            image={data.floeten}
            imageFun={data.floetenFun}
          />
          <MvRegister name="Oboen" image={data.oboen} />
          <MvRegister
            name="Klarinetten"
            image={data.klarinetten}
            imageFun={data.klarinettenFun}
          />
          <MvRegister name="Saxofone" image={data.sax} imageFun={data.saxFun} />
          <MvRegister
            name="Tenorhörner"
            image={data.tenorhoerner}
            imageFun={data.tenorhoernerFun}
          />
          <MvRegister
            name="Flügelhörner"
            image={data.fluegelhoerner}
            imageFun={data.fluegelhoernerFun}
          />
          <MvRegister
            name="Trompeten"
            image={data.trompeten}
            imageFun={data.trompetenFun}
          />
          <MvRegister
            name="Posaunen"
            image={data.posaunen}
            imageFun={data.posaunenFun}
          />
          <MvRegister
            name="Hörner"
            image={data.hoerner}
            imageFun={data.hoernerFun}
          />
          <MvRegister
            name="Tuben"
            image={data.tuben}
            imageFun={data.tubenFun}
          />
          <MvRegister
            name="Schlagwerk"
            image={data.schlagwerk}
            imageFun={data.schlagwerkFun}
          />
        </MvTextPageSection>

        <MvTextPageSection title="Jugendkapelle">
          <p>
            Lange ist es her, als unter der Leitung von Karl Nachtrieb 'Vetter'
            1953 die erste Jugendkapelle des Musikvereins gegründet wurde. Im
            Schnitt waren sie gerade mal zarte zwölf bis dreizehn Jahre alt, die
            ersten Jungmusiker des Vereins: Helmut Göltz, Siegfried und Dieter
            Linsenmaier, Werner Mertz, Hans Rapp und Peter Sihler. Doch obwohl
            jeder der oben Aufgeführten dem Verein lange als aktiver Musiker
            treu geblieben ist, musste die erste Jugendkapelle mangels Nachwuchs
            aufgegeben werden.
          </p>
          <MvImage
            fluid={data.jugendkapelle.childImageSharp.fluid}
            caption="Jugendkapelle im Jahr 2019"
            className="my-8"
            photographer="Benjamin Rupp"
          />
          <p>
            Erst 1974 startete Richard Keil einen neuen Versuch. Dieser ist bis
            heute erfolgreich fortgesetzt worden, obwohl die Zeit freilich
            regelmäßig größere Umbrüche mit sich bringt, wenn die Alterspanne
            zwischen 25-jährigen "Jugendlichen" und 9-jährigen "Anfängern" zu
            groß wird.
          </p>
          <p>
            Von 1995 an leitete Andreas Ziegenhorn die Jugendkapelle. 2004
            durfte sie bei ihrem ersten Wertungsspiel antreten. Das Ergebnis
            (man ist versucht zu sagen »wie könnte es auch anders sein…«): »Sehr
            gut«! Mit dieser Note könnte gleichzeitig die Stimmung unter den
            jungen Musikern während Auftritten (z.B. bei der Maibaumaufstellung,
            beim Stauseefest oder der Winterunterhaltung), vor allem aber bei
            außermusikalischen Aktivitäten wie Schlittschuhlaufen, gemeinsamen
            Radtouren, Zeltwochenende, Kinderfasching, Altpapiersammlung,
            Weihnachtsfeier oder Fußballturnier bewertet werden.
          </p>
          <p>
            Der Musikverein ist stolz auf seinen Nachwuchs, der aktuell von
            Sonja Nachtrieb geleitet wird.
          </p>
        </MvTextPageSection>

        <MvTextPageSection title="Ehrenmitglieder">
          <div className="flex flex-wrap justify-center">
            {data.ehrenmitglieder.edges.map(({ node }) => {
              let displayName = node.name
              if (node.title) {
                displayName += ` (${node.title})`
              }
              return (
                <div
                  key={node.name}
                  className="bg-white-dark shadow m-1 px-2 py-1"
                >
                  {displayName}
                </div>
              )
            })}
          </div>
        </MvTextPageSection>

        <MvTextPageSection title="Frauentreff">
          <div className="flex flex-col md:flex-row items-center">
            <MvImage
              fluid={data.frauentreff.childImageSharp.fluid}
              caption="Frauentreff im Jahr 2011"
              className="w-full md:w-1/2 flex-no-shrink mx-8 mb-8"
            />
            <div className="flex flex-col">
              <p>
                Am Vereinsleben teilnehmen, sich kennen lernen, aus diesem
                Gedanken entstand der Frauentreff. Was ganz zaghaft begann,
                entwickelte sich zur megastarken Truppe. Bei unseren Treffen am
                1. Freitag des Monats hat Geselligkeit oberste Priorität.
              </p>
              <Link to="/verein/frauentreff" className="mt-6 self-end">
                mehr vom Frauentreff
              </Link>
            </div>
          </div>
        </MvTextPageSection>
      </MvTextPage>
    </Layout>
  )
}

export const query = graphql`
  query {
    frauentreff: file(
      relativePath: { eq: "pages/verein/frauentreff2011.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    trachtenkapelle: file(
      relativePath: { eq: "pages/verein/trachtenkapelle2023.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    floeten: file(relativePath: { eq: "pages/verein/register/floeten.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    floetenFun: file(
      relativePath: { eq: "pages/verein/register/floeten_fun.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    oboen: file(relativePath: { eq: "pages/verein/register/oboen.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    klarinetten: file(
      relativePath: { eq: "pages/verein/register/klarinetten.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    klarinettenFun: file(
      relativePath: { eq: "pages/verein/register/klarinetten_fun.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sax: file(relativePath: { eq: "pages/verein/register/sax.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    saxFun: file(relativePath: { eq: "pages/verein/register/sax_fun.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tenorhoerner: file(
      relativePath: { eq: "pages/verein/register/tenorhoerner.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tenorhoernerFun: file(
      relativePath: { eq: "pages/verein/register/tenorhoerner_fun.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fluegelhoerner: file(
      relativePath: { eq: "pages/verein/register/fluegelhoerner.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fluegelhoernerFun: file(
      relativePath: { eq: "pages/verein/register/fluegelhoerner_fun.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    trompeten: file(
      relativePath: { eq: "pages/verein/register/trompeten.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    trompetenFun: file(
      relativePath: { eq: "pages/verein/register/trompeten_fun.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    posaunen: file(relativePath: { eq: "pages/verein/register/posaunen.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    posaunenFun: file(
      relativePath: { eq: "pages/verein/register/posaunen_fun.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hoerner: file(relativePath: { eq: "pages/verein/register/hoerner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hoernerFun: file(
      relativePath: { eq: "pages/verein/register/hoerner_fun.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tuben: file(relativePath: { eq: "pages/verein/register/tuben.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tubenFun: file(
      relativePath: { eq: "pages/verein/register/tuben_fun.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    schlagwerk: file(
      relativePath: { eq: "pages/verein/register/schlagwerk.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    schlagwerkFun: file(
      relativePath: { eq: "pages/verein/register/schlagwerk_fun.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    dirigent: file(relativePath: { eq: "pages/verein/dirigent-2024.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 760) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jugendkapelle: file(
      relativePath: { eq: "pages/verein/jugendkapelle2019.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ehrenmitglieder: allEhrenmitgliederJson(sort: { fields: memberSince }) {
      edges {
        node {
          name
          title
        }
      }
    }
  }
`
