import React, { useState } from 'react'
import MvImage from './common/MvImage'
import registerStyles from './MvRegister.module.css'

export default ({ name, image, imageFun }) => {
  return (
    <>
      <h3 className="mt-12">{name}</h3>
      <div className={registerStyles.container}>
        <MvImage fluid={image.childImageSharp.fluid} />
        {imageFun && <MvImage fluid={imageFun.childImageSharp.fluid} />}
        {!imageFun && <span />}
        <span className="text-xs text-grey">Fotos: Daniel Stimac</span>
      </div>
    </>
  )
}
