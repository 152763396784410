import React, { useState } from 'react'
import GatsbyImage from 'gatsby-image'
import Lightbox from 'fslightbox-react'

export default ({
  fluid,
  caption,
  photographer,
  className,
  style,
  disableToggle = false,
}) => {
  const [toggler, setToggler] = useState(false)

  return (
    <div className={className}>
      <Lightbox toggler={toggler} sources={[fluid.src]} />

      <div onClick={() => !disableToggle && setToggler(!toggler)}>
        <GatsbyImage fluid={fluid} style={style} />
      </div>

      <div className="flex">
        {photographer && (
          <span className="ml-4 text-xs text-grey">Foto: {photographer}</span>
        )}

        {caption && (
          <span className="gradient px-2 py-1 ml-auto -mt-4 mb-4 mr-4 text-xs md:text-base z-50">
            {caption}
          </span>
        )}
      </div>
    </div>
  )
}
